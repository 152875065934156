import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxScrollTopModule } from 'ngx-scrolltop';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { DemosComponent } from './components/pages/demos/demos.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ParallaxComponent } from './components/pages/parallax/parallax.component';
import { ParallaxDarkComponent } from './components/pages/parallax-dark/parallax-dark.component';
import { ParallaxTextRotateComponent } from './components/pages/parallax-text-rotate/parallax-text-rotate.component';
import { OwlCarouselVideoComponent } from './components/pages/owl-carousel-video/owl-carousel-video.component';
import { OwlCarouselVideoDarkComponent } from './components/pages/owl-carousel-video-dark/owl-carousel-video-dark.component';
import { CreativeAgencyComponent } from './components/pages/creative-agency/creative-agency.component';
import { CreativeAgencyDarkComponent } from './components/pages/creative-agency-dark/creative-agency-dark.component';
import { BlogsComponent } from './components/pages/blogs/blogs.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    DemosComponent,
    ParallaxComponent,
    ParallaxDarkComponent,
    ParallaxTextRotateComponent,
    OwlCarouselVideoComponent,
    OwlCarouselVideoDarkComponent,
    CreativeAgencyComponent,
    CreativeAgencyDarkComponent,
    BlogsComponent,
    ErrorPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxScrollTopModule,
    CarouselModule,
    BrowserAnimationsModule,
    NgbModule
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [provideClientHydration()],
  bootstrap: [AppComponent]
})
export class AppModule { }
