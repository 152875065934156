<app-navbar></app-navbar>

<div class="transition-none">
    <section class="title-hero-bg parallax-effect" style="background-image: url(assets/images/background/parallax-bg-2.jpg);">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="page-title text-center white-color">
              <h1 class="raleway-font font-300">Blog Posts</h1>
              <div class="breadcrumb mt-20">
                  <!-- Breadcrumb Start -->
                      <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blog Details</li>
                      </ul>
                  <!-- Breadcrumb End -->
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
</div>

<section class="white-bg">
    <div class="container">
      <div class="row">
        <div class="col-md-9 col-sm-9 col-xs-12 xs-mb-50">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 mb-50">
              <ul class="single-blog-list">
                <li *ngFor="let blog of blogs">
                  <div class="date-box">
                    <span class="day default-bg">{{blog.date}}</span>
                    <span class="month">{{blog.month}}</span>
                  </div>
                  <div class="post-wrap">
                    <div class="post-img">
                        <owl-carousel-o [options]="customOptions" class="blog-grid-slider blog-nav owl-btn-center-lr slides">
                            <ng-template carouselSlide *ngFor="let slide of slideStore">
                              <div class="item">
                                <img class="img-responsive" src="assets/images/post/{{slide.image}}" alt=""/>
                              </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                    <div class="post-text">
                      <h3><a routerLink="/blogs">{{blog.title}}</a></h3>
                      <p>{{blog.desc}}</p>
                      <a routerLink="/blogs" class="btn-text">Read More</a>
                    </div>
                  </div>
                  <div class="post-meta">
                      <span><i class="icofont icofont-user-male"></i><a routerLink="/blogs">{{blog.authorName}}</a></span> <span><i class="icofont icofont-ui-tag"></i>{{blog.category}}</span>
                      <span><i class="icofont icofont-comment"></i><a routerLink="/blogs">{{blog.comment}}</a></span> <span><i class="icofont icofont-simple-right"></i><a routerLink="/blogs">Read More</a></span>
                  </div>
                </li>
              </ul>
            </div>
            <!--== Post End ==-->

            <div class="col-md-12 col-sm-12 col-xs-12 mb-50">
              <h2 class="comment-reply-title mt-0">15 Comments</h2>
              <ul class="comment-box">
              <li class="post-comment">
                <div class="comment-content"> <a routerLink="/blogs" class="avatar"><img src="assets/images/team/avatar-1.jpg" alt="img-1"></a>
                  <div class="post-body">
                    <div class="comment-header"> <span class="author"><a routerLink="/blogs">Karl Casey</a></span> <span class="time-ago"><a routerLink="/blogs">25 mins ago</a></span> </div>
                    <div class="post-message">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam justo, ullamcorper tincidunt pellentesque in, condimentum ut enim.</p>
                    </div>
                    <div class="comment-footer"> <span class="reply"><a routerLink="/blogs"><i class="ion-reply"></i> Reply</a></span> </div>
                  </div>
                </div>
                <ul class="reply-comment">
                  <li class="post-comment">
                    <div class="comment-content"> <a routerLink="/blogs" class="avatar"><img src="assets/images/team/avatar-2.jpg" alt="#"></a>
                      <div class="post-body">
                        <div class="comment-header"> <span class="author"><a routerLink="/blogs">Lynda Stone</a></span> <span class="time-ago"><a routerLink="/blogs">18 days ago</a></span> </div>
                        <div class="post-message">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </div>
                        <div class="comment-footer"> <span class="reply"><a routerLink="/blogs"><i class="ion-reply"></i> Reply</a></span> </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <li class="post-comment">
                <div class="comment-content"> <a routerLink="/blogs" class="avatar"><img src="assets/images/team/avatar-3.jpg" alt="#"></a>
                  <div class="post-body">
                    <div class="comment-header"> <span class="author"><a routerLink="/blogs">Roland Buford</a></span> <span class="time-ago"><a routerLink="/blogs">3 months ago</a></span> </div>
                    <div class="post-message">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam justo, ullamcorper tincidunt pellentesque in, condimentum ut enim.</p>
                    </div>
                    <div class="comment-footer"> <span class="reply"><a routerLink="/blogs"><i class="ion-reply"></i> Reply</a></span> </div>
                  </div>
                </div>
              </li>
            </ul>

            <h2 class="recent-post-title">Recent Posts</h2>
            <div class="row">
                <div *ngFor="let blog of recentBlogs" class="col-md-6 blog-list">
                    <div class="post-wrap xs-mb-30 sm-mb-30">
                      <div class="post-img">
                        <owl-carousel-o [options]="customOptionsTwo" class="blog-grid-slider owl-btn-center-lr">
                          <ng-template carouselSlide *ngFor="let slide of slideImgs">
                            <div class="item"><img class="img-responsive" src="{{slide.image}}" alt=""/></div>
                          </ng-template>
                        </owl-carousel-o>
                      </div>
                      <div class="date-box">
                        <span class="day default-bg">{{blog.date}}</span>
                        <span class="month">{{blog.month}}</span>
                      </div>
                      <div class="post-text">
                        <h3><a routerLink="/blog">{{blog.title}}</a></h3>
                        <p>{{blog.desc}}</p>
                        <a routerLink="/blog" class="btn-text">Read More</a>
                      </div>
                    </div>
                  </div>
            </div>
          </div>


          </div>
          <div class="row mt-100">
              <div class="col-md-12">
                  <div class="text-center">
                    <div class="pagination text-uppercase dark-color justify-content-center">
                          <ul>
                              <li><a routerLink="/blogs"><i class="icofont icofont-long-arrow-left mr-5 xs-display-none"></i> Prev</a></li>
                              <li class="active"><a routerLink="/blogs">1</a></li>
                              <li><a routerLink="/blogs">2</a></li>
                              <li><a routerLink="/blogs">3</a></li>
                              <li><a routerLink="/blogs">Next <i class="icofont icofont-long-arrow-right ml-5 xs-display-none"></i></a></li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
        </div>

        <div class="col-md-3 col-sm-3 col-xs-12">
          <div class="mb-50">
            <h5 class="aside-title">About Me</h5>
            <a routerLink="/blogs">
              <img src="assets/images/about-me.jpg" alt="about-me" />
            </a>
            <p class="font-300 mt-30">Lorem ipsum dolor sit amet consectetur adipiscing elit. Sed dui lorem adipiscing in adipiscing et.</p>
            <a class="btn btn-md btn-dark-outline btn-square mt-10">About Author</a>
          </div>
          <div class="sidebar_widget widget_archive mb-50">
            <h5 class="aside-title">Archive</h5>
            <ul>
              <li> <a routerLink="/blogs">January 2018</a> <span>14</span></li>
              <li> <a routerLink="/blogs">May 2017</a> <span>8</span></li>
              <li> <a routerLink="/blogs">June 2017</a> <span>63</span></li>
              <li> <a routerLink="/blogs">Febuary 2012</a> <span>45</span></li>
              <li> <a routerLink="/blogs">April 2016</a> <span>88</span></li>
            </ul>
          </div>
          <div class="social-icons-style-06 mb-50">
            <h5 class="aside-title text-uppercase">Follow Us</h5>
            <ul class="xs-icon">
              <li><a class="icon facebook" routerLink="/blogs"><i class="icofont icofont-facebook"></i></a></li>
              <li><a class="icon twitter" routerLink="/blogs"><i class="icofont icofont-twitter"></i></a></li>
              <li><a class="icon tumblr" routerLink="/blogs"><i class="icofont icofont-tumblr"></i></a></li>
              <li><a class="icon flicker" routerLink="/blogs"><i class="icofont icofont-flikr"></i></a></li>
              <li><a class="icon instagram" routerLink="/blogs"><i class="icofont icofont-instagram"></i></a></li>
            </ul>
          </div>

          <div class="sidebar_widget widget_categories mb-50">
            <h5 class="aside-title">Categories</h5>
            <ul>
              <li> <a routerLink="/blogs">Business</a></li>
              <li> <a routerLink="/blogs">Health</a> </li>
              <li> <a routerLink="/blogs">Motion Graphic</a> </li>
              <li> <a routerLink="/blogs">Conecpt Design</a> </li>
              <li> <a routerLink="/blogs">Lifestyle</a> </li>
            </ul>
          </div>
          <div class="sidebar_widget widget_tag_cloud mb-50">
            <h5 class="aside-title">Tags</h5>
            <div class="post-tags"> <a routerLink="/blogs">Branding</a> <a routerLink="/blogs">Marketing</a> <a routerLink="/blogs">Photography</a> <a routerLink="/blogs">Illustration</a> <a routerLink="/blogs">Development</a> <a routerLink="/blogs">Multipurpose</a> <a routerLink="/blogs">Creativity</a> <a routerLink="/blogs">Apps</a>  <a routerLink="/blogs">Fashion</a> <a routerLink="/blogs">Concept</a> <a routerLink="/blogs">Design</a></div>
          </div>

          <div class="sidebar_widget widget_recent_post mb-50">
            <h5 class="aside-title">Recent Posts</h5>
            <div class="post-item">
              <div class="post-thumb">
                <a href=""><img width="75" height="75" src="assets/images/post/post-img-1.jpg" class="img-responsive" alt="post-01"/></a>
              </div>
              <div class="post-body">
                <span class="font-14px"><a href="">Web Design</a></span>
                <div class="post-time">1/01/2019 </div>
                <a href="" class="subtitle">Travel Adventure</a>
              </div>
            </div>
            <div class="post-item">
              <div class="post-thumb">
                <a href=""><img width="75" height="75" src="assets/images/post/post-img-2.jpg" class="img-responsive" alt="post-02"/></a>
              </div>
              <div class="post-body">
                <span class="font-14px"><a href="">Small Business</a></span>
                <div class="post-time">5/03/2019 </div>
                <a href="" class="subtitle">Photography</a>
              </div>
            </div>
            <div class="post-item">
              <div class="post-thumb">
                <a href=""><img width="75" height="75" src="assets/images/post/post-img-3.jpg" class="img-responsive" alt="post-03" /></a>
              </div>
              <div class="post-body">
                <span class="font-14px"><a href="">SEO Marketing</a></span>
                <div class="post-time">4/05/2019 </div>
                <a href="" class="subtitle">Web Development</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </section>