import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrl: './blogs.component.css'
})
export class BlogsComponent {
  // OWl Slider
  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    margin:0,
    nav:true,
    autoplay:true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    items: 1,
    navText: ['<i class="icofont icofont-arrow-left"></i>', '<i class="icofont icofont-arrow-right"></i>'],
  }

  slideStore = [
    {
      image: "post-02.jpg",
    },

    {
      image: "post-03.jpg",
    },

    {
      image: "post-04.jpg",
    }

  ]

  //Blogs
  blogs = [
    {
      title: "Illustration",
      desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum [...]",
      date: 15,
      comment: "35 Comments",
      month: "NOV",
      authorName: "Hugo Adams",
      category: "News, Events"
    },
    {
      title: "Gaming",
      desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum [...]",
      date: 23,
      comment: "35 Comments",
      month: "DEC",
      authorName: "Andrew Smith",
      category: "Gaming, Trending"
    },
    {
      title: "Photography Tips",
      desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum [...]",
      date: 10,
      comment: "35 Comments",
      month: "JAN",
      authorName: "Enviro John",
      category: "Designs, Trending"
    }
  ]

  // Owl Blogs
  customOptionsTwo: OwlOptions = {
    loop: true,
    dots: false,
    margin:30,
    nav:true,
    autoplay:true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    navText: ['<i class="icofont icofont-arrow-left"></i>', '<i class="icofont icofont-arrow-right"></i>'],
    items: 3,
    responsive:{
      0:{
          items:1,
      },
      600:{
          items:2,
      },
      1000:{
          items:3,
      }
    }
  }

  slideImgs = [
    {image: "assets/images/post/post-01.jpg",},
    {image: "assets/images/post/post-02.jpg",},
    {image: "assets/images/post/post-03.jpg",},
    {image: "assets/images/post/post-04.jpg",},
    {image: "assets/images/post/post-05.jpg",},
    {image: "assets/images/post/post-06.jpg",}
  ];

  //Blogs
  recentBlogs = [
    {
      date: 20,
      month: 'NOV',
      title: 'Photography Tips',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
      date: 17,
      month: 'OCT',
      title: 'Gaming Trends',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    }
  ];
}
