import { Component, HostListener } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import emailjs, { type EmailJSResponseStatus } from '@emailjs/browser';

@Component({
  selector: 'app-creative-agency-dark',
  templateUrl: './creative-agency-dark.component.html',
  styleUrl: './creative-agency-dark.component.css'
})
export class CreativeAgencyDarkComponent {
  //Owl Slider
  customOptions: OwlOptions = {
    loop: true,
    dots: true,
    margin:0,
    nav:true,
    autoplay:true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    items: 1,
    navText: ['<i class="icofont icofont-arrow-left"></i>', '<i class="icofont icofont-arrow-right"></i>'],
  }

  slideStore = [
    {
      introtext: "Xtract",
      title: "Welcome to",
      subtitle: "CREATIVE AGENCY / WEBSITE DEVELOPMENT / COPYWRITER",
      image: 'assets/images/slides/home-bg-1.jpg'
    },

    {
      introtext: "Creative",
      title: "Clean + ",
      subtitle: "CREATIVE AGENCY / WEBSITE DEVELOPMENT / COPYWRITER",
      image: 'assets/images/slides/home-bg-2.jpg'
    },

    {
      introtext: "Mobile",
      title: "Desktop + ",
      subtitle: "CREATIVE AGENCY / WEBSITE DEVELOPMENT / COPYWRITER",
      image: 'assets/images/slides/home-bg-3.jpg'
    }

  ]

  // Features
  features =[
    {
      icon: "icon-laptop font-40px",
      title: "Friendly Codes",
      desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      icon: "icon-globe font-40px",
      title: "SEO Optimized",
      desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      icon: "icon-dial font-40px",
      title: "Fast Support",
      desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    }
  ]

  // Video Popup
	isOpen = false;
  openPopup(): void {
      this.isOpen = true;
  }
  closePopup(): void {
      this.isOpen = false;
  }

  // Video Player
  isMuted: boolean = true; // Start with the video muted

  toggleMute(): void {
    this.isMuted = !this.isMuted;
  }

  //Team
  teams = [
    {
      img: 'team-01.jpg',
      title: 'Zachary Watkins',
      subTitle: 'Lead UI/UX Designer'
    },
    {
      img: 'team-02.jpg',
      title: 'Laura Aguilar',
      subTitle: 'Marketing Head'
    },
    {
      img: 'team-03.jpg',
      title: 'Scott Shaw',
      subTitle: 'Creative Director'
    },
    {
      img: 'team-04.jpg',
      title: 'Katherine James',
      subTitle: 'Project Manager'
    }
  ];

  socialLinks = [
    { icon: 'icofont icofont-behance'},
    { icon: 'icofont icofont-dribbble'},
    { icon: 'icofont icofont-facebook'},
  ];

  //Counters
  counters = [
    {
      icon: 'icon-trophy font-70px',
      number: 3521,
      title: 'Happy Clients',
      displayedNumber: 0
    },
    {
      icon: 'icon-toolbox font-70px',
      number: 974,
      title: 'Cups of Coffee',
      displayedNumber: 0
    },
    {
      icon: 'icon-lifesaver font-70px',
      number: 634,
      title: 'Project Delivered',
      displayedNumber: 0
    },
    {
      icon: 'icon-linegraph font-70px',
      number: 449,
      title: 'Awards Won',
      displayedNumber: 0
    },
  ];

  ngOnInit(): void {
    this.startCounters();
  }

  startCounters(): void {
    this.counters.forEach(counter => {
      this.animateCounter(counter);
    });
  }

  animateCounter(counter: any): void {
    const duration = 4000; 
    const intervalTime = 50; 
    const increment = counter.number / (duration / intervalTime);

    const interval = setInterval(() => {
      counter.displayedNumber += increment;
      if (counter.displayedNumber >= counter.number) {
        counter.displayedNumber = counter.number;
        clearInterval(interval); 
      }
    }, intervalTime);
  }

  //Services
  services = [
    {
      icon: 'icon-pencil font-40px',
      title: 'Web Design',
      desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      icon: 'icon-tablet font-40px',
      title: 'Branding',
      desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      icon: 'icon-pictures font-40px',
      title: 'Digital Solutions',
      desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      icon: 'icon-desktop font-40px',
      title: 'Mobile Apps',
      desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      icon: 'icon-layers font-40px',
      title: 'SEO Services',
      desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      icon: 'icon-lightbulb font-40px',
      title: 'Copywriter',
      desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    }
  ]

  // Skills
  skillsLeft = [
    { name: 'HTML', level: 90 },
    { name: 'CSS', level: 80 },
    { name: 'JavaScript', level: 85 },
  ];

  skillsRight = [
    { name: 'React', level: 65 },
    { name: 'Angular', level: 75 },
    { name: 'TypeScript', level: 70},
  ];

  isScrolled = false;

  @HostListener('window:scroll', [])
  onScroll(): void {
    const skillsSection = document.querySelector('.skill');
    
    // Add a null check for skillsSection
    if (skillsSection) {
      const position = skillsSection.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      // Check if the section is in view
      if (position < windowHeight) {
        this.isScrolled = true;
      }
    }
  }

  // Portfolio
  portfolios = [
    {
      img: "1.jpg",
      title: "Project Name 1",
      subTitle: "Branding, Website"
    },
    {
      img: "2.jpg",
      title: "Project Name 2",
      subTitle: "Branding, UI/UX"
    },
    {
      img: "3.jpg",
      title: "Project Name 3",
      subTitle: "Design, Stationery"
    },
    {
      img: "4.jpg",
      title: "Project Name 4",
      subTitle: "Branding, UI/UX"
    },
    {
      img: "5.jpg",
      title: "Project Name 5",
      subTitle: "Branding, Mockup"
    },
    {
      img: "6.jpg",
      title: "Project Name 6",
      subTitle: "Design, Branding"
    }
  ];

  //Portfolio Lightbox
  isLightboxOpen = false;
  currentImage: string | null = null;

  openImage(img: string): void {
    this.currentImage = img;
    this.isLightboxOpen = true;
  }

  closeLightbox(): void {
    this.isLightboxOpen = false;
    this.currentImage = null;
  }

  // Owl Testimonial
  customOptionsTwo: OwlOptions = {
    loop: true,
    dots: true,
    margin:30,
    nav:false,
    autoplay:true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    items: 3,
    responsive:{
      0:{
          items:1,
      },
      600:{
          items:2,
      },
      1000:{
          items:3,
      }
    }
  }

  slideStoreTwo = [
    {
      title: "Anne McAdams",
      subTitle: "Customer",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sodales nec nulla ac aliquet. Duis vel nunc eget.",
      image: "assets/images/team/avatar-1.jpg",
    },

    {
      title: "Jared Kane",
      subTitle: "Customer",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sodales nec nulla ac aliquet. Duis vel nunc eget.",
      image: "assets/images/team/avatar-2.jpg",
    },

    {
      title: "Nani Wale",
      subTitle: "Customer",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sodales nec nulla ac aliquet. Duis vel nunc eget.",
      image: "assets/images/team/avatar-3.jpg",
    },

    {
      title: "John Doe",
      subTitle: "Customer",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sodales nec nulla ac aliquet. Duis vel nunc eget.",
      image: "assets/images/team/avatar-4.jpg",
    }

  ];

  // Owl Blogs
  customOptionsThree: OwlOptions = {
    loop: true,
    dots: false,
    margin:30,
    nav:true,
    autoplay:true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    navText: ['<i class="icofont icofont-arrow-left"></i>', '<i class="icofont icofont-arrow-right"></i>'],
    items: 3,
    responsive:{
      0:{
          items:1,
      },
      600:{
          items:2,
      },
      1000:{
          items:3,
      }
    }
  }

  slideImgs = [
    {image: "assets/images/post/post-01.jpg",},
    {image: "assets/images/post/post-02.jpg",},
    {image: "assets/images/post/post-03.jpg",},
    {image: "assets/images/post/post-04.jpg",},
    {image: "assets/images/post/post-05.jpg",},
    {image: "assets/images/post/post-06.jpg",}
  ];

  //Blogs
  blogs = [
    {
      date: 20,
      month: 'NOV',
      title: 'Photography Tips',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
      date: 17,
      month: 'OCT',
      title: 'Gaming Trends',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
      date: 31,
      month: 'DEC',
      title: 'Illustration Guide',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
  ];

  // Owl Brand Logos
  customOptionsFour: OwlOptions = {
    loop: true,
    dots: false,
    margin:0,
    nav:false,
    autoplay:true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    items: 6,
    responsive:{
      0:{
          items:1,
      },
      600:{
          items:2,
      },
      1000:{
          items:6,
      }
    }
  }

  slideStoreFour = [
    {image: "assets/images/clients/client-img-1-light.png"},
    {image: "assets/images/clients/client-img-2-light.png"},
    {image: "assets/images/clients/client-img-3-light.png"},
    {image: "assets/images/clients/client-img-4-light.png"},
    {image: "assets/images/clients/client-img-5-light.png"},
    {image: "assets/images/clients/client-img-6-light.png"},
    {image: "assets/images/clients/client-img-7-light.png"},
    {image: "assets/images/clients/client-img-8-light.png"},
    {image: "assets/images/clients/client-img-9-light.png"},
    {image: "assets/images/clients/client-img-10-light.png"}

  ];

  // Contact Form
  formData = {
    user_name: '',
    user_email: '',
    message: ''
  };

  // EmailJS service configuration
  private serviceId = 'contact-form-service';  // Replace with your EmailJS Service ID
  private templateId = 'contact-form-xtract';  // Replace with your EmailJS Template ID
  private publicKey = 'WBWOP-CjIrLEz-vHU';  // Replace with your EmailJS Public Key

  // Method to send email on form submission
  public sendEmail(event: Event): void {
    event.preventDefault();

    // Send email using EmailJS
    emailjs.sendForm(this.serviceId, this.templateId, event.target as HTMLFormElement, this.publicKey)
      .then(
        (response: EmailJSResponseStatus) => {
          alert('Message sent successfully. Thank you, we will get back to you soon!');
          this.resetForm();
        },
        (error: EmailJSResponseStatus) => {
          console.error('Failed to send message. Error:', error.text);
          alert('Failed to send the message. Please try again later.');
        }
      );
  }

  // Method to reset the form after submission
  private resetForm(): void {
    this.formData = {
      user_name: '',
      user_email: '',
      message: ''
    };
  }
}
