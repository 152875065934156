import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  //Social Media

  socialLinks = [
    {
      icon:'icofont icofont-facebook'
    },
    {
      icon:'icofont icofont-twitter'
    },
    {
      icon:'icofont icofont-behance'
    },
    {
      icon:'icofont icofont-dribble'
    },
    {
      icon:'icofont icofont-linkedin'
    }
  ]
}
