import { Component } from '@angular/core';

@Component({
  selector: 'app-demos',
  templateUrl: './demos.component.html',
  styleUrl: './demos.component.css'
})
export class DemosComponent {
  items = [
    {
      img: 'demo-1.jpg',
      url: '/parallax',
      title: 'Parallax'
    },
    {
      img: 'demo-2.jpg',
      url: '/parallax-dark',
      title: 'Parallax Dark'
    },
    {
      img: 'demo-3.jpg',
      url: '/parallax-text-rotate',
      title: 'Parallax Text Rotate'
    },
    {
      img: 'demo-4.jpg',
      url: '/owl-carousel-video',
      title: 'Owl Carousel Video'
    },
    {
      img: 'demo-5.jpg',
      url: '/owl-carousel-video-dark',
      title: 'Owl Carousel Video Dark'
    },
    {
      img: 'demo-6.jpg',
      url: '/creative-agency',
      title: 'Creative Agency'
    },
    {
      img: 'demo-7.jpg',
      url: '/creative-agency-dark',
      title: 'Creative Agency Dark'
    },
    {
      img: 'demo-8.jpg',
      url: '/blogs',
      title: 'Blogs'
    },
    {
      img: 'demo-9.jpg',
      url: '/error-page',
      title: 'Error Page'
    },
  ]
}
