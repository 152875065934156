import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DemosComponent } from './components/pages/demos/demos.component';
import { ParallaxComponent } from './components/pages/parallax/parallax.component';
import { ParallaxDarkComponent } from './components/pages/parallax-dark/parallax-dark.component';
import { ParallaxTextRotateComponent } from './components/pages/parallax-text-rotate/parallax-text-rotate.component';
import { OwlCarouselVideoComponent } from './components/pages/owl-carousel-video/owl-carousel-video.component';
import { OwlCarouselVideoDarkComponent } from './components/pages/owl-carousel-video-dark/owl-carousel-video-dark.component';
import { CreativeAgencyComponent } from './components/pages/creative-agency/creative-agency.component';
import { CreativeAgencyDarkComponent } from './components/pages/creative-agency-dark/creative-agency-dark.component';
import { BlogsComponent } from './components/pages/blogs/blogs.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';

const routes: Routes = [
  {path: '', component: DemosComponent},
  {path: 'parallax', component: ParallaxComponent},
  {path: 'parallax-dark', component: ParallaxDarkComponent},
  {path: 'parallax-text-rotate', component: ParallaxTextRotateComponent},
  {path: 'owl-carousel-video', component: OwlCarouselVideoComponent},
  {path: 'owl-carousel-video-dark', component: OwlCarouselVideoDarkComponent},
  {path: 'creative-agency', component: CreativeAgencyComponent},
  {path: 'creative-agency-dark', component: CreativeAgencyDarkComponent},
  {path: 'blogs', component: BlogsComponent},
  {path: 'error-apge', component: ErrorPageComponent},
  // Here add new pages component

  {path: '**', component: ErrorPageComponent} // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
