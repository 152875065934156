<app-navbar></app-navbar>
<section class="parallax-bg fixed-bg lg-screen pt-0 pb-0" style="background-image: url('assets/images/slides/home-bg-1.jpg');" id="home">
    <div class="hero-text-wrap lg-screen">
      <div class="hero-text">
        <div class="text-content-slider">
            <owl-carousel-o [options]="customOptions" class="owl-btn-center-lr">
                <ng-template carouselSlide *ngFor="let slide of slideStore">
                    <div class="container">
                        <div class="row d-flex justify-content-center">
                            <div class="col-md-12">
                                <div class="all-padding-50 text-center white-color">
                                    <p class="font-400">{{slide.subtitle}}</p>
                                    <h1 class="raleway-font text-uppercase">{{slide.title}} <span class="default-color">{{slide.introtext}}</span></h1>
                                    <p class="mt-30">
                                        <a class="btn btn-lg btn-light-outline btn-square">View Our Works</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
      </div>
    </div>
</section>

<section class="dark-bg xs-pb-100" id="about">
    <div class="container">
      <div class="row">
        <div class="col-md-8 centerize-col text-center">
          <div class="section-title">
            <h2 class="raleway-font default-color">Who We Are</h2>
            <hr class="center_line default-bg">
            <h1 class="raleway-font white-color">Digital & Professionals Agency</h1>
          </div>
        </div>
      </div>
      <div class="row mt-50">
        <div *ngFor="let feature of features;" class="col-md-4 col-sm-4 col-xs-12">
         <div class="feature-box text-center xs-mb-30 feature-box-default-bg center-feature dark-box">
         	<i [ngClass]="feature.icon"></i>
          	<h4 class="mt-0 font-600">{{feature.title}}</h4>
          	<p class="font-400">{{feature.desc}}</p>
          </div>
        </div>
      </div>
    </div>
</section>

<section class="black-bg xs-pb-60">
    <div class="col-md-6 col-sm-6 bg-flex bg-flex-left">
      <div class="bg-flex-holder bg-flex-cover" style="background-image: url(assets/images/bg-left-img-2.jpg);">
        <div class="video-box_overlay">
          <div class="center-layout">
             <div class="v-align-middle"> 
                <a (click)="openPopup()">
                    <div class="play-button"><i class="tr-icon ion-android-arrow-dropright"></i></div>
                </a> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="col-md-6 col-sm-6 offset-md-6 offset-sm-6">
        <div class="col-inner spacer text-left">
          <h2 class="raleway-font mt-0 font-20px xs-font-17px default-color">Our Amazing Story</h2>
          <h1 class="raleway-font mt-0 font-50px font-600 xs-font-27px white-color">We are group of professionals focused on making creative and innovative works</h1>
          <p class="mt-30 intro">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <div class="row">
              <div class="col-md-6">
                  <h3 class="white-color">Our Vision</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
              </div>
              <div class="col-md-6">
                  <h3 class="white-color">Our Mission</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
              </div>
            </div>
        </div>
      </div>
    </div>
</section>

  <!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/sU3FkzUKHXU?si=vhtmR7XG-9HXU6Ij" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='icofont icofont-close'></i>
        </button>
    </div>
</div>

<section class="relative">
    <div class="parallax-overlay"></div>
    <div class="container">
      <div class="row">
        <div class="display-table col-md-8 offset-md-2 col-sm-12">
          <div class="v-align-middle text-center">
            <div class="white-color text-center">
              <h1 class="font-700 font-60px line-height-60 xs-font-40px xs-line-height-50 sm-font-60px sm-line-height-70 play-font">Ready to Rock?</h1>
              <hr class="center_line default-bg">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt<br /> ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation<br /> ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in<br /> reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              </p>
              <p class="mt-30">
                <a class="btn btn-lg btn-light-outline btn-square">Get Started</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
     <video autoplay [muted]="isMuted" loop class="html5-video">
      <source src="assets/videos/home-video.mp4" type="video/mp4">
      <source src="assets/videos/home-video.webm" type="video/webm">
    </video>
</section>

<section class="black-bg pb-0" id="team">
    <div class="container">
      <div class="row">
        <div class="col-md-8 centerize-col text-center">
          <div class="section-title">
            <h1 class="raleway-font white-color">Our Creative Team</h1>
            <hr class="center_line default-bg">
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row mt-50">
        <div *ngFor="let team of teams" class="col-md-3 col-sm-6 col-xs-12 remove-padding">
          <div class="o-team-wrap">
            <div class="o-team-img">
              <div class="o-team-media-img">
                <img src="assets/images/team/{{team.img}}" alt="your-image">
              </div>
            </div>
            <div class="o-tean-content">
              <div class="o-tean-content-inner">
                <h4 class="mt-10 white-color text-uppercase">{{team.title}}</h4>
					  		<p class="default-color">{{team.subTitle}}</p>
                <div class="o-team-social-icons">
                  <a *ngFor="let social of socialLinks" href="javascript:void(0);" class="o-team-social-icon">
                    <span [ngClass]="social.icon"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--== Member End ==-->
      </div>

    </div>
  </section>

  <section class="parallax-bg fixed-bg sm-pb-80 sm-pt-80" style="background-image: url('assets/images/background/parallax-bg-2.jpg');">
    <div class="overlay-bg"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 centerize-col text-center">
          <div class="section-title">
            <h1 class="raleway-font white-color">Fun Facts</h1>
            <hr class="center_line default-bg">
          </div>
        </div>
      </div>
      <div class="row mt-50 row-flex flex-center">
          <div *ngFor="let counter of counters" class="col-md-3 col-sm-6 col-xs-12 xs-mb-50 sm-mb-50">
            <div class="counter-wrap white-color">
              <i [ngClass]="counter.icon"></i>
              <h2><span class="counter font-600">{{counter.displayedNumber | number:'1.0-0'}}</span></h2>
              <h3 class="font-200 text-uppercase">{{counter.title}}</h3>
            </div>
          </div>
        </div>
    </div>
  </section>

  <section class="dark-bg pb-0" id="services">
    <div class="container">
      <div class="row">
        <div class="col-md-8 centerize-col text-center">
          <div class="section-title">
            <h1 class="raleway-font white-color">Our Services</h1>
            <hr class="center_line default-bg">
          </div>
        </div>
      </div>

      <div class="row mt-50">
        <div *ngFor="let service of services" class="col-md-4 dark feature-box text-left mb-50 col-sm-6">
          <div class="float-start">
            <i [ngClass]="service.icon"></i>
          </div>
          <div class="float-end">
            <h4 class="mt-0 font-600 raleway-font white-color">{{service.title}}</h4>
            <p class="font-400">{{service.desc}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-12 text-center">
        <img src="assets/images/screens.png" alt="xtract-img" class="img-responsive screens"/>
      </div>

    </div>
  </section>

  <section class="parallax-bg fixed-bg sm-pb-80 sm-pt-80" style="background-image: url('assets/images/background/parallax-bg.jpg');">
    <div class="overlay-bg"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 centerize-col text-center">
          <div class="section-title">
            <h1 class="raleway-font white-color">Our Skills</h1>
            <hr class="center_line default-bg">
          </div>
        </div>
      </div>
    	<div class="row">
            <div class="col-md-6 mt-30 col-xs-12 xs-mt-0">
              <div class="skillbar-wrap skillbar-style-01">
                <div *ngFor="let skill of skillsLeft">
                  <div class="skillbar">
                    <span class="skillbar-title white-color font-18px">{{ skill.name }}</span>
                    <p class="skillbar-bar default-bg" role="progressbar" [style.width]="skill.level + '%'" [attr.aria-valuenow]="skill.level" aria-valuemin="0" aria-valuemax="100"></p>
                    <span class="skill-bar-percent">{{ skill.level }}%</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 mt-30 col-xs-12 xs-mt-0">
              <div class="skillbar-wrap skillbar-style-01">
                <div *ngFor="let skill of skillsRight">
                  <div class="skillbar">
                    <span class="skillbar-title white-color font-18px">{{ skill.name }}</span>
                    <p class="skillbar-bar default-bg" role="progressbar" [style.width]="skill.level + '%'" [attr.aria-valuenow]="skill.level" aria-valuemin="0" aria-valuemax="100"></p>
                    <span class="skill-bar-percent">{{ skill.level }}%</span>
                  </div>
                </div>
              </div>
            </div>

        </div>
    </div>
  </section>

  <section class="black-bg pb-0" id="works">
    <div class="container">
      <div class="row">
        <div class="col-md-8 centerize-col text-center">
          <div class="section-title">
            <h1 class="raleway-font white-color">Our Works</h1>
            <hr class="center_line default-bg">
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid remove-padding">

      <div class="row mt-50">
        <div *ngFor="let portfolio of portfolios; let i = index" class="col-md-4 col-sm-6 remove-padding">
          <div class="portfolio gallery-image-hover text-center">
              <div class="folio-overlay"></div>
              <img src="assets/images/portfolio/grid/{{portfolio.img}}" alt="portfolio-image" (click)="openImage(portfolio.img)">
              <div class="portfolio-wrap">
                  <div class="portfolio-description">
                    <h3 class="portfolio-title mt-0">{{portfolio.title}}</h3>
                    <h6 class="links">{{portfolio.subTitle}}</h6>
                  </div>
                  <ul class="portfolio-details">
                    <li><a (click)="openImage(portfolio.img)"><i class="icofont icofont-search"></i></a></li>
                  </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Modal for Lightbox Effect -->
<div class="lightbox" *ngIf="isLightboxOpen" (click)="closeLightbox()">
  <button class="close-button" (click)="closeLightbox()"><i class="icofont icofont-close-circled"></i></button>
  <img [src]="'assets/images/portfolio/grid/' + currentImage" alt="Lightbox Image" />
</div>

<section class="parallax-bg fixed-bg sm-pb-80 sm-pt-80" style="background-image: url('assets/images/background/parallax-bg-3.jpg');" id="testimonials">
  <div class="overlay-bg"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-8 centerize-col text-center">
        <div class="section-title">
          <h1 class="raleway-font white-color">Our Testimonials</h1>
          <hr class="center_line default-bg">
        </div>
      </div>
    </div>
    <div class="row">
      <owl-carousel-o [options]="customOptionsTwo" class="testimonial owl-btn-center-lr">
        <ng-template carouselSlide *ngFor="let slide of slideStoreTwo">
            <div class="testimonial-item">
                <div class="testimonial-content">
                    <i class="icofont icofont-quote-left font-50px default-color mt-20"></i>
                    <img class="img-responsive rounded-circle text-center" src="{{slide.image}}" alt="" />
                    <div class="text-center">
                      <h4 class="font-600 mb-0 raleway-font white-color">{{slide.title}}</h4>
                      <span class="default-color font-14px">{{slide.subTitle}}</span>
                      <div class="clearfix mb-20"></div>
                      <p class="mt-20 line-height-26 font-14px white-color">{{slide.description}}</p>
                    </div>
                </div>
            </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</section>

<section class="dark-bg" id="blogs">
  <div class="container">
    <div class="row">
      <div class="col-md-8 centerize-col text-center">
        <div class="section-title">
          <h1 class="raleway-font white-color">Our Blogs</h1>
          <hr class="center_line default-bg">
        </div>
      </div>
    </div>
    <div class="row mt-50">
      <div *ngFor="let blog of blogs" class="col-md-4 blog-list">
        <div class="post-wrap xs-mb-30 sm-mb-30">
          <div class="post-img">
            <owl-carousel-o [options]="customOptionsThree" class="blog-grid-slider owl-btn-center-lr">
              <ng-template carouselSlide *ngFor="let slide of slideImgs">
                <div class="item"><img class="img-responsive" src="{{slide.image}}" alt=""/></div>
              </ng-template>
            </owl-carousel-o>
          </div>
          <div class="date-box">
            <span class="day default-bg">{{blog.date}}</span>
            <span class="month">{{blog.month}}</span>
          </div>
          <div class="post-text dark">
            <h3><a routerLink="/blog">{{blog.title}}</a></h3>
            <p>{{blog.desc}}</p>
            <a routerLink="/blog" class="btn-text dark">Read More</a>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</section>

<div class="black-bg pt-60 pb-60">
  <div class="container">
    <div class="row">
      <div class="client-slider">
        <owl-carousel-o [options]="customOptionsFour" class="owl-btn-center-lr">
            <ng-template carouselSlide *ngFor="let slide of slideStoreFour">
                <div class="client-logo">
                    <img class="img-responsive" src="{{slide.image}}" alt="Brand Logo" />
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    </div>
  </div>
</div>

<section class="parallax-bg fixed-bg sm-pb-80 sm-pt-80" style="background-image: url('assets/images/background/parallax-bg-4.jpg');" id="contact">
  <div class="overlay-bg"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-8 centerize-col text-center">
        <div class="section-title">
          <h1 class="raleway-font white-color">Contact Us</h1>
          <hr class="center_line default-bg">
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-70">
      <div class="col-md-6 col-sm-6 col-xs-12">
        <form (submit)="sendEmail($event)" class="contact-form-style-01 xs-mb-50" #contactForm="ngForm">
          <div class="row">
              <div class="col-md-6 col-sm-6">
                  <div class="form-group">
                      <label class="visually-hidden" for="user_name">Name</label>
                      <input type="text" minlength="2" maxlength="15" [class.is-invalid]="userName.invalid && userName.touched" name="user_name" class="md-input" id="name" placeholder="Name *" [(ngModel)]="formData.user_name" #userName="ngModel" required>
                      <div *ngIf="userName.invalid && userName.touched" class="invalid-feedback mb-10">
                          Name is required.
                      </div>
                  </div>
              </div>
              <div class="col-md-6 col-sm-6">
                  <div class="mb-3">
                      <label class="visually-hidden" for="user_email">Email</label>
                      <input type="email" minlength="2" maxlength="35"
                          [class.is-invalid]="userEmail.invalid && userEmail.touched" name="user_email"
                          class="md-input" id="email" placeholder="Email *" [(ngModel)]="formData.user_email"
                          #userEmail="ngModel" required email>
                      <div *ngIf="userEmail.invalid && userEmail.touched" class="invalid-feedback mb-10">
                          A valid email is required.
                      </div>
                  </div>
              </div>
              <div class="col-md-12 col-sm-12">
                  <div class="mb-3">
                      <label class="visually-hidden" for="message">Project Details</label>
                      <textarea name="message" [class.is-invalid]="userMessage.invalid && userMessage.touched"
                          class="md-textarea" id="message" rows="7" placeholder="Project Details"
                          [(ngModel)]="formData.message" #userMessage="ngModel" required></textarea>
                      <div *ngIf="userMessage.invalid && userMessage.touched" class="invalid-feedback mb-10">
                          Project details are required.
                      </div>
                  </div>
              </div>
              <div class="col-md-12 col-sm-12">
                  <div class="text-left mt-20">
                      <button type="submit" name="submit" class="btn btn-outline btn-md btn-square btn-animate remove-margin" [disabled]="contactForm.invalid" value="Send">
                          <span>Send Message <i class="ion-android-arrow-forward"></i></span>
                      </button>
                  </div>
              </div>
          </div>
        </form>
      </div>

      <div class="col-md-6 col-sm-6 col-xs-12 xs-mb-50">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="section-title">
              <h2 class="raleway-font default-color">Postal Address</h2>
              <p class="mt-30">PO Box 16122 Toronto Eaton Centre, 220 The PATH Toronto, ON M5B 2H1, Canada</p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="section-title">
              <h2 class="raleway-font default-color">Office Numbers</h2>
              <p class="mb-0 mt-30">Landline : +44 1234 567 9</p>
              <p class="mb-0">Mobile : +44 1234 567 9</p>
            </div>
          </div>
        </div>
        <div class="row mt-20">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="section-title">
              <h2 class="raleway-font default-color">Our Email</h2>
              <p class="mb-0 mt-30">Order : order&#64;yourwebsite.com</p>
              <p class="mb-0">Request : request&#64;yourwebsite.com</p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="section-title">
              <h2 class="raleway-font default-color">Fast Support</h2>
              <p class="mb-0 mt-30">Support : support&#64;yourwebsite.com</p>
              <p class="mb-0">Career : career&#64;yourwebsite.com</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>