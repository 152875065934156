<section class="parallax-bg fixed-bg lg-screen lg-section" style="background-image: url('assets/images/slides/home-bg-2.jpg')" id="home">
    <div class="hero-text-wrap lg-screen transparent-bg">
      <div class="hero-text">
        <div class="container">
          <div class="row">
            <div class="col-md-12 centerize-col">
              <div class="text-center white-color">
                  <h1 class="text-uppercase mt-0">Xtract <span class="default-color">Agency</span></h1>
                  <h2 class="font-100 raleway-font">The Creative One Page Template</h2>
                  <h4 class="font-300 xs-line-height-40">Based on Bootstrap 5x Responsive Angular and <br>One page Parallax Template</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>

<section class="grey-bg pb-80">
  <div class="container">
    <div class="row">
      <div class="col-md-8 centerize-col text-center">
        <div class="section-title">
          <h1 class="raleway-font">Choose the template you like</h1>
        </div>
      </div>
    </div>
    <div class="row mt-50">
      <div *ngFor="let item of items"  class="col-md-4 col-sm-4 col-xs-12">
        <div class="product">
          <div class="product-wrap">
            <img src="assets/images/demo-imgs/{{item.img}}" class="img-responsive" alt="demo" />
            <div class="product-caption">
              <div class="product-description text-center">
                <div class="product-description-wrap">
                  <div class="product-title"> 
                    <a [routerLink]="item.url" class="btn btn-light-outline btn-lg btn-square" target="_blank">Live Preview</a> 
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="product-detail text-center preview-box">
            <h3 class="raleway-font">{{item.title}}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>